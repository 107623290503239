

















import { AuthModule } from "@/store/modules";
import { RootRouter } from "@/utils/newRoutePathConstant";
import { Component, Vue } from "vue-property-decorator";
import helper from "@/utils/helpers";

@Component
export default class FacebookLogin extends Vue {
  code: string = "";
  state: string = "";
  message: string = "Trying to login using facebook";

  redirect() {
    let profile = helper.getUserProfile() as any;
    if (profile && profile.id) {
      if (profile.userType) {
        this.$router.push(RootRouter.NewHome);
      } else {
        this.$router.push({ name: "NewHome" });
      }
    }
  }

  async created() {
    this.code = this.$route.query.code as string;
    this.state = this.$route.query.state as string;
    const state = localStorage.getItem("state");
    if (this.state && this.state == state && this.code && this.code != "") {
      await AuthModule.facebookLogin({ code: this.code });
      this.redirect();
    } else {
      this.message = "Could not login through facebook";
    }
    localStorage.removeItem("state");
  }

  onLoginClick() {
    this.$bvModal.show("login");
  }
}
